import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/contentful.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  aboutus;

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    this.contentfulService.getContent('about')
    .then(aboutus => {
      this.aboutus = aboutus[0].fields;
      console.log(this.aboutus);
    });
  }

}
