<!-- Page Header-->
<header class="section page-header page-header-1">
    <!-- RD Navbar-->
    <div class="rd-navbar-wrap">
        <nav class="rd-navbar rd-navbar-modern" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fixed"
            data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static"
            data-xl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static"
            data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="10px" data-xl-stick-up-offset="10px"
            data-xxl-stick-up-offset="10px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
            <div class="rd-navbar-main">
                <!-- RD Navbar Panel-->
                <div class="rd-navbar-panel">
                    <!-- RD Navbar Toggle-->
                    <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                    <!-- RD Navbar Brand-->
                    <div class="rd-navbar-brand"> <a class="brand" href="index.html"><img class="brand-logo-dark"
                                src="assets/logo.png"  /></a></div>
                </div>
                <div class="rd-navbar-nav-wrap">
                    <!-- RD Navbar Nav-->
                    <ul class="rd-navbar-nav">
                        <li class="rd-nav-item"><a class="rd-nav-link" routerLink="">Home</a>
                        </li>
                        <li class="rd-nav-item"><a class="rd-nav-link" routerLink="/about">About</a>
                        </li>
                        <li class="rd-nav-item"><a class="rd-nav-link" routerLink="/service">Service</a>
                        </li>
                        <li class="rd-nav-item"><a class="rd-nav-link" routerLink="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>

</header>