import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/contentful.service';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contact;

  settings = SITE_SETTINGS

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    this.contentfulService.getContent('contact')
    .then(services => {
      this.contact = services[0].fields;
      console.log(this.contact);
    });
  }
}
