<div *ngIf="aboutus">

  <section class="breadcrumbs-custom">
    <div class="breadcrumbs-custom-main context-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9">
            <h2 class="breadcrumbs-custom-title">About Us</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumbs-custom-aside">
      <div class="container">
        <ul class="breadcrumbs-custom-path">
          <li><a href="index.html">Home</a></li>
          <li class="active">About Us</li>
        </ul>
      </div>
    </div>
  </section>
  
  <section class="section section-md">
    <div class="container">
      <div class="row row-50 align-items-center">
        <div class="col-md-8 col-lg-7 col-xl-6">
          <h3>PROVIDING QUALITY TAXI SERVICES</h3>
          <p>In this moment of crisis with a growing pandemic about one's surroundings, we are taking at most care of hygiene of our cars to ensure your safety.

          </p>
          <!-- Quote Light-->
          <blockquote class="quote-light quote-light_1">
            <div class="quote-light-mark linearicons-quote-open"></div>
            <div class="quote-light-text">
              <p>Mercy Droptaxi is one of India's best and safe taxi booking service designed exclusive for satisfying customers on their cab and travel needs.

              </p>
            </div>
          </blockquote>
        </div>
        <div class="col-md-4 col-lg-5 col-xl-6">
          <div class="inset-xl-left-40">
            <div class="thumbnail-classy">
              <div class="thumbnail-classy-image bg-image" style="background-image: url({{aboutus.aboutImage.fields.file.url}});"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</div>
