
<div *ngIf="services">

  <section class="breadcrumbs-custom">
    <div class="breadcrumbs-custom-main context-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9">
            <h2 class="breadcrumbs-custom-title">Services</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumbs-custom-aside">
      <div class="container">
        <ul class="breadcrumbs-custom-path">
          <li><a href="index.html">Home</a></li>
          <li><a href="#">Pages</a></li>
          <li class="active">Services</li>
        </ul>
      </div>
    </div>
  </section>
  
  <!-- <section class="section text-center">
    <div class="container">
      <div class="list-blocks-outer">
        <div class="list-blocks">
          <div *ngFor="let service of services" class="list-blocks-item">
            <div class="list-blocks-item-inner">
              <div class="list-blocks-icon mdi mdi-account-plus"></div>
              <p class="list-blocks-title">{{service.fields.serviceName}}</p>
              <p>{{service.fields.serviceDesc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="section section-lg bg-gray-100 text-center">
    <div class="container"><a class="button button-secondary button-winona" href="#"><div class="content-original">Order a taxi</div><div class="content-dubbed">Order a taxi</div></a></div>
  </section> -->

  <section class="section section-md bg-gray-100">
    <div class="container">
      <div class="row row-30 row-md-50 row-xl-60">

        <div *ngFor="let service of services" class="col-sm-6 col-lg-4 d-flex wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
          <!-- Box Modern-->
          <article class="box-modern" data-anime="circles-1">
            <div class="box-modern-media">
              <div class="box-modern-icon mdi mdi-{{service.fields.serviceIcon}}"></div>
              <div class="box-modern-circle box-modern-circle-1" style="transform: translateX(0px) translateY(0px) scale(1);"></div>
              <div class="box-modern-circle box-modern-circle-2" style="transform: translateX(0px) translateY(0px) scale(1);"></div>
            </div>
            <p class="box-modern-title">{{service.fields.serviceName}}</p>
            <div class="box-modern-text">
              <p>{{service.fields.serviceDesc}}</p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</div>