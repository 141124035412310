import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/contentful.service';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contact;
  wpNumber;
  websiteSettings;
  webPhoneNumber;
  email;

  settings = SITE_SETTINGS;

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    this.contentfulService.getContent('contact')
    .then(services => {
      this.contact = services[0].fields;
      console.log(this.contact);
    });

    this.contentfulService.getContent('websiteSettings')
    .then(websiteSettings => {
      this.websiteSettings = websiteSettings[0].fields;
      localStorage.setItem('websiteSettings', JSON.stringify(this.websiteSettings));
    });

    let setting = JSON.parse(localStorage.getItem('websiteSettings'));
    this.email = setting.email;
    this.wpNumber = setting.whatsapp;
    this.webPhoneNumber = setting.phone;
    console.log('wpNumber', this.wpNumber);

  }

}
