export const SITE_SETTINGS = {
    isCMS: false,
    isDatabaseEnable: false,
    isTelegramEnabled: true,
    isRentalFixedPrice: true,
    isSMSEnabled: false,
    OTP: false,
    siteName: 'Rithi Droptaxi',
    phoneNo: '7418157697',
    whatsapp: '+917418157697',
    websiteAddress: 'www.rithidroptaxi.in',
    contentAPI: "https://cdn.contentful.com/spaces/brr64dmu4x7k/environments/master/entries?access_token=ZGqVnk_hMQpvltuisuLov_WYlzskHeELc9GAW1XpPoc",
    smsKey: 'eC4z5IhWtBXNmFE3sdnQSguUr2ix09fkP6YARLbcjJOwy17VvM9FvmoZcPhuD0nJyLga5IKfA7RB68jW',
    enquiryEmail: 'rithidroptaxi@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '',
    tGroup: '',
    OTPApi: 'https://us-central1-smsapi-service.cloudfunctions.net/otp/api/v1/sendsms',
    logoURL: 'https://rithidroptaxi.com/assets/logo.png'
}