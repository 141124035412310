<!-- Messenger of The Next Generation-->
<!-- FScreen-->
<div>

  <div class="layout-4">
    <div class="layout-4-item-right">
      <div class="box-custom-2 bg-accent">
        <div class="box-custom-2-bg">
          <div class="box-custom-2-bg-inner">
            <div class="box-custom-2-bg-image" style="background-image: url(images/index-2-1397x1397.png);">
            </div>
          </div>
        </div>
        <div class="box-custom-2-inner">
          <div class="contacts-default" style="padding: 20px;">
            <h2 class="wow fadeIn color">{{settings.siteName}}</h2>

            <div class="unit align-items-center">
              <div class="unit-left">
                <div class="contacts-default-icon mdi mdi-phone-incoming"></div>
              </div>
              <div class="unit-body">
                <a class="contacts-default-link color" href="tel:{{settings.phoneNo}}">
                  <span class="material-icons">
                    call
                  </span>
                  +91-{{settings.phoneNo}}</a>
              </div>
            </div>
          </div>
          <!-- RD Mailform-->
          <div class="eael-tabs-nav" style="padding-top: 25px;">
            <ul class="eael-tab-top-icon">
    
              <li (click)="tripTypeSelect('One Way')" id="flight" [ngClass]="tripType === 'One Way' ? 'active': 'inactive'">
                <mat-icon>local_taxi</mat-icon>
                <span class="eael-tab-title title-after-icon">One Way</span>
              </li>
    
              <li (click)="tripTypeSelect('Round Trip')" id="hotels"
                [ngClass]="tripType === 'Round Trip' ? 'active': 'inactive'">
                <mat-icon>360</mat-icon>
                <span class="eael-tab-title title-after-icon">Round Trip</span>
              </li>
    
    
    
    
            </ul>
          </div>
    
          <div id="booking">
            <ng-container *ngIf="tripType === 'One Way'">
              <app-oneway></app-oneway>
            </ng-container>
            <ng-container *ngIf="tripType === 'Round Trip'">
              <app-roundtrip></app-roundtrip>
            </ng-container>
    
          </div>
    
        </div>
      </div>
    </div>
    <div class="layout-4-item-left"><img src="assets/images/idnex-1-747x597.png" alt="" width="747" height="597" />
    </div>
  </div>



  <section class="section section-md bg-gray-100 text-center puttop" id="features">
    <div class="container">
      <div class="row">
        <div class="col-md-6">

          <h3 class="wow fadeIn" data-wow-delay=".1s">One Way Tariff
          </h3>
          <div class="table-outer table-responsive-lg">
            <table class="table-custom table-custom-primary text-center">
              <thead>
                <tr>
                  <th scope="col">Vehicle Type</th>
                  <th scope="col">Rate per KM</th>
                  <th scope="col">Driver Bata</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let car of cars ">
                  <td>{{ car.carType }}</td>
                  <td><strong>₹ {{ car.oneway }}/KM</strong></td>
                  <td>₹ {{ car.onewayDriverFee }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <h3 class="wow fadeIn" data-wow-delay=".1s">Round Trip Tariff
          </h3>
          <div class="table-outer table-responsive-lg">
            <table class="table-custom table-custom-primary text-center">
              <thead>
                <tr>
                  <th scope="col">Vehicle Type</th>
                  <th scope="col">Rate per KM</th>
                  <th scope="col">Driver Bata</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let car of cars ">
                  <td>{{ car.carType }}</td>
                  <td><strong>₹ {{ car.roundtrip}}/KM</strong></td>
                  <td>₹ {{ car.roundtripDriverFee }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>
  </section>


  <section class="section oh">
    <div class="container">
      <div class="row justify-content-center justify-content-lg-between">
        <div class="col-md-10 col-lg-5 col-xl-4">
          <div class="section-md">
            <h3>HOW WE WORK
            </h3>
            <p class="offset-top-3">
              * 100% sanitized cars. * 24/7 customer and cab services available. * Fast and secure cars with best
              service. * Safe journey without peak pricing.


            </p><a class="button button-secondary button-winona" href="tel:{{settings.phoneNo}}">
              <div class="content-original">Order a taxi</div>
              <div class="content-dubbed">Order a taxi</div>
            </a>
          </div>
        </div>
        <div class="col-md-10 col-lg-7">
          <!-- Timeline-->
          <div class="timeline-classic timeline-classic_1">
            <div class="timeline-classic-item">
              <div class="timeline-classic-item-inner">
                <div class="timeline-classic-item-decoration"></div>
                <p class="timeline-classic-title"><span class="text-primary">Step 1.</span><span>CHOOSE A PICKUP
                    ADDRESS</span></p>
                <div class="timeline-classic-text">
                  <p>Relax, sit back and enjoy your ride along we drop you home to whatever location you want the drop
                    to be.


                  </p>
                </div>
              </div>
            </div>
            <div class="timeline-classic-item">
              <div class="timeline-classic-item-inner">
                <div class="timeline-classic-item-decoration"></div>
                <p class="timeline-classic-title"><span class="text-primary">Step 2.</span><span>CHOOSE YOUR
                    DESTINATION</span></p>
                <div class="timeline-classic-text">
                  <p>
                    Do you any requirements of cab preferences like particular car types, we provide all types of cabs
                    to satisfy your needs.


                  </p>
                </div>
              </div>
            </div>
            <div class="timeline-classic-item">
              <div class="timeline-classic-item-inner">
                <div class="timeline-classic-item-decoration"></div>
                <p class="timeline-classic-title"><span class="text-primary">Step 3.</span><span>PICK A DRIVER</span>
                </p>
                <div class="timeline-classic-text">
                  <p>Do you have any driver preferences all you do is just mention. We provide you the type of driver
                    you hire.</p>
                </div>
              </div>
            </div>
            <div class="timeline-classic-item">
              <div class="timeline-classic-item-inner">
                <div class="timeline-classic-item-decoration"></div>
                <p class="timeline-classic-title"><span class="text-primary">Step 4.</span><span>SELECT A PREFERRED
                    CAR</span></p>
                <div class="timeline-classic-text">
                  <p>Do you any requirements of cab preferences like particular car types, we provide all types of cabs
                    to satisfy your needs.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>