import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { LocationStrategy } from '@angular/common';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
 
  @ViewChild('stepper') stepper: MatStepper;

  tripType = 'One Way';
  cars = CARS;
  settings = SITE_SETTINGS;


  constructor(private _formBuilder: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private location: LocationStrategy,

  ) { }

  ngOnInit() {

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.stepper.previous();
    });
  }

  tripTypeSelect(type) {
    this.tripType = type;
  }
}
