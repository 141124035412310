
<div *ngIf=contact>

<section class="breadcrumbs-custom">
  <div class="breadcrumbs-custom-main context-dark">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <h2 class="breadcrumbs-custom-title">Contact Us</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="breadcrumbs-custom-aside">
    <div class="container">
      <ul class="breadcrumbs-custom-path">
        <li><a href="index.html">Home</a></li>
        <li><a href="#">Pages</a></li>
        <li class="active">Contact Us</li>
      </ul>
    </div>
  </div>
</section>


<section class="section section-md">
  <div class="container">
    <div class="row row-50">
      <div class="col-xl-5">
        <div class="inset-1">
          <h3>{{contact.ourLocation}}</h3>
          <p>{{contact.ourLocationDesc}}</p>
          <div class="row row-50">
            <div class="col-sm-6 col-xl-12">
              <p class="heading-7">{{contact.office1}}</p>
              <ul class="list list-style-1">
                <li class="unit"><span class="unit-left icon icon-sm text-primary mdi mdi-map-marker"></span>
                  <div class="unit-body">Chennai</div>
                </li>
                <li class="unit"><span class="unit-left icon icon-sm text-primary mdi mdi-cellphone"></span>
                  <div class="unit-body"><a href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a></div>
                </li>
                <li class="unit"><span class="unit-left icon icon-sm text-primary mdi mdi-email-outline"></span>
                  <div class="unit-body"><a class="text-primary" href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-5 col-xl-6">
        <div>
          <div class="thumbnail-classy">
            <div class="thumbnail-classy-image bg-image" style="background-image: url({{contact.image.fields.file.url}});"></div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>
</div>