import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private client = createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken
  })

  constructor() { }

  getContent(contentType, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType
    }, query))
      .then(res => res.items);
  }

}
