<!-- Page Footer-->

<div *ngIf="contact">

    <footer class="section footer-classic" style="background-color: #fff;">
        <div class="footer-classic-main">
            <div class="container">
                <div class="row row-50 justify-content-lg-between">
                    <div class="col-sm-7 col-lg-6 col-xl-6"><a class="brand" href="index.html"><img
                                class="brand-logo-dark" src="assets/logo.png" alt="" width="200" height="33"
                                srcset="assets/logo.png" /></a>
                        <p><span>We provide the best taxi services for you all over the Tamilnadu. Trust the leading and
                                the most reliable taxi service accross Tamilnadu

                            </span></p>
                    </div>

                    <div class="col-sm-7 col-lg-5 col-xl-3">
                        <h5 class="footer-classic-title">Quick Links</h5>
                        <ul class="list footer-classic-list footer-classic-list_1-cols">
                            <li><a routerLink="">Home</a></li>
                            <li><a routerLink="/service">Services</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/contact">FAQ</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-5 col-lg-9 col-xl-2">
                        <h5 class="footer-classic-title">Contacts</h5>
                        <div class="row row-20 row-sm-35">
                            <div class="col-6 col-sm-12 col-lg-8 col-xl-12">
                                <div class="row row-10 text-black">
                                    <div class="col-lg-6 col-xl-12">
                                        <p>Karanai puduchery main road
                                            Urrapakkam 603210</p>
                                    </div>
                                    <div class="col-lg-6 col-xl-12"><a class="text-primary"
                                            href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></div>
                                    <div class="col-lg-6 col-xl-12"><a
                                            href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-classic-aside">
            <div class="container">
                <div class="row">
                    <p class="rights col-md-4"><span>Designed & Developed by <a
                                href="https://www.calvincareinfotech.com">CalvinCare InfoTech</a></span></p>
                    <p class="rights col-md-8"><span>&copy;&nbsp; </span><span class="copyright-year"></span><span>. All
                            Rights Reserved by {{settings.siteName}}</span></p>
                </div>

            </div>
        </div>
    </footer>

</div>


  <!--Start Sticky Icon-->
  <div class="sticky-icon">
    <a href="tel:{{settings.phoneNo}}" class="Instagram"><i class="fa fa-phone"></i> Call Us </a>
    <!-- <a href="https://www.facebook.com/" class="Facebook"><i class="fab fa-facebook-f"> </i> Facebook </a> -->
    <a href="https://wa.me/{{settings.whatsapp}}" class="whatsapp"><i class="fab fa-whatsapp"> </i>whatsapp </a>
    <!-- <a href="https://www.youtube.com/" class="Youtube"><i class="fab fa-youtube"></i> Youtube </a>
    <a href="https://twitter.com/login" class="Twitter"><i class="fab fa-twitter"> </i> Twitter </a>    -->
  </div>
  <!--End Sticky Icon-->